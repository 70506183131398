import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

export const FaqPostTemplate = ({
  question,
  helmet,
}) => {
  return (
    <section className="section">
      {helmet || ''}
      <div className="container is-max-desktop content">
        <div className="columns">
          <div className="column">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {question}
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

FaqPostTemplate.propTypes = {
  question: PropTypes.string,
  helmet: PropTypes.object,
}

const FaqPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FaqPostTemplate
        description={post.html}
        helmet={
          <Helmet titleTemplate="%s | Faq">
            <title>{`${post.frontmatter.question}`}</title>
            <meta
              name="description"
              content={`${post.html}`}
            />
          </Helmet>
        }
        title={post.frontmatter.question}
      />
    </Layout>
  )
}

FaqPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default FaqPost

export const pageQuery = graphql`
  query FaqPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        question
      }
    }
  }
`
